import * as React from "react"
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Layout from "components/Layout"
import Seo from "components/seo"
import Home from "./Home"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <Routes>
      <Route exact path="/" element={<Home />} />
    </Routes>
  </Layout>
);

export const Head = () => <Seo title="Home" />

export default IndexPage
